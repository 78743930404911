import "spotlight.js";

const sources = [];

document.querySelectorAll("[data-lightbox]").forEach((element, i) => {
    const src = element.getAttribute("data-lightbox");

    if (src) {
        sources.push({ src });
    }

    element.addEventListener("click", () => {
        if (sources.length > 0) {
            Spotlight.show(sources, {
                index: i + 1,
                theme: "white",
                animation: "fade",
                infinite: true,
            });
        }
    });
});
