// Alpine
import Alpine from 'alpinejs'

// Initialize alpine

// Plugins
import Swiper from 'swiper';

// Extend webpack window classes

// Add to window
window.Swiper = Swiper;
window.Alpine = Alpine;
window.Alpine.start();
